/*!
 * Bootstrap v5.0.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";

$primary: #6c5dd3;
$secondary: #ffa2c0;
$success: #46bcaa;
$info: #0038ff;
$warning: #ffcf52;
$danger: #f35421;
$light: #748fb5;
$dark: #1f2128;

@import "~bootstrap/scss/variables";

$enable-shadows:              true;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

//	stylelint-disable-next-line no-invalid-position-at-import-rule
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// $font-family-base: 'Montserrat', sans-serif;
$font-family-base: "Poppins", var(--bs-font-sans-serif);

$font-size-root: .8125rem; // `13px`
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;

$border-radius: 1rem;
$border-radius-sm: $border-radius * .875;
$border-radius-lg: $border-radius * 1.25;

$box-shadow: 0 1.6rem 3rem rgba($black, .1);
$box-shadow-sm: 0 .8rem 3rem rgba($black, .075);
$box-shadow-lg: 0 1.6rem 3rem rgba($black, .175);

$navbar-nav-link-padding-x: 1rem;
$navbar-light-color:                rgba($black, .45);

$navbar-nav-link-padding-x: 2rem;

$card-border-radius: $spacer;
$card-border-color: transparent;
$card-box-shadow: $box-shadow;

// Create your own map
$brand: #ffcd02 !default;
$brand-two: #323232 !default;
$transparent: transparent !default;
$storybook: #ff4785 !default;

$custom-colors: (
  "brand": $brand,
  "brand-two": $brand-two,
  "transparent": $transparent,
  "storybook": $storybook
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

:root {
  // stylelint-disable declaration-no-important
  font-size: var(--#{$variable-prefix}root-font-size) !important;
  // stylelint-enable declaration-no-important
}

body {
  overflow-x: hidden;
}

.navbar-nav {

  .nav-item * {
    font-weight: 600;
    font-size: $font-size-lg;
  }

  .nav-link {
    transition: $transition-base;
  }

  .show > .nav-link,
  .nav-link.active {
    color: $dark;
  }
}

.header {
  background: rgba(256, 256, 256, .5);
  backdrop-filter: blur(.6rem);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.scroll-margin {
  scroll-margin: 100px;
}

.effect-hover {
  transition: $transition-base;
  background: $light;
  overflow: hidden;

  > img {
    transition: $transition-base;
    top: 1rem;
    position: relative;
    box-shadow: $box-shadow-lg;
  }
  &:hover {
    transform: scale(1.03);
    background: $info;
    >img {
      top: 0;
      box-shadow: none;
    }
  }
}

.padding-block {
  display: flex;
  text-align: center;
  font-size: 5vmax;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  color: #333;
}

.box {
  flex-shrink: 0;

  /* need to hardcode box sizes, else images loading throws off calculations */

  /* could be a job for imagesLoaded */
  width: 90vh;
  height: 70vh;
  margin: 6vh 0;
}

.c-h-lg-down-100 {
  @include media-breakpoint-down(lg) {
    height: 100%;
  }
}

.landing-device {
  @include media-breakpoint-down(xl) {
    transform: scale(.5);
  }

  @include media-breakpoint-down(md) {
    transform: unset;
    zoom: .5;
    position: relative;
    overflow-x: hidden;
    height: 150vw;
  }
}
.demo-background {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='236.717' height='185.496' viewBox='0 0 236.717 185.496'%3E%3Cpath id='Path_2932' data-name='Path 2932' d='M597.557,172.981c-8.5-10.91-21.592-19.2-35.4-18.36-24.79,1.505-40.117,29.8-64.263,35.6-12.246,2.945-25.059-.346-37.089-4.078-7.644-2.371-15.4-4.948-23.258-6.244h-.006a55.243,55.243,0,0,0-13.623-.684c-.481.04-.946.1-1.4.165-12.751,1.1-21.239,6.22-26.659,10.984a41.271,41.271,0,0,0-7.25,7.939l0,0h0a62.111,62.111,0,0,0-6.544,11.976,93.657,93.657,0,0,0,79.042,129.48c28.031,2.213,55.665-8.3,79.937-22.493,25.307-14.8,48.794-34.6,61.583-60.979S615.576,196.107,597.557,172.981Z' transform='translate(-375 -154.563)' style='&%2310; fill: %23ecf0ff;&%2310;'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: 10% 50%;
}

.layout-background {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='236.717' height='185.496' viewBox='0 0 236.717 185.496'%3E%3Cpath id='Path_2932' data-name='Path 2932' d='M597.557,172.981c-8.5-10.91-21.592-19.2-35.4-18.36-24.79,1.505-40.117,29.8-64.263,35.6-12.246,2.945-25.059-.346-37.089-4.078-7.644-2.371-15.4-4.948-23.258-6.244h-.006a55.243,55.243,0,0,0-13.623-.684c-.481.04-.946.1-1.4.165-12.751,1.1-21.239,6.22-26.659,10.984a41.271,41.271,0,0,0-7.25,7.939l0,0h0a62.111,62.111,0,0,0-6.544,11.976,93.657,93.657,0,0,0,79.042,129.48c28.031,2.213,55.665-8.3,79.937-22.493,25.307-14.8,48.794-34.6,61.583-60.979S615.576,196.107,597.557,172.981Z' transform='translate(-375 -154.563)' style='&%2310; fill: %23e0dff5;&%2310;'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: 70% 50%;

}

.object-fit-contain {
  object-fit: contain;
  object-position: center;
}

.slide-item {
  svg,
  img {
    height: 50px;
    object-fit: contain;
    object-position: center;
    filter: grayscale(1);
    opacity: .5;
    transition: $transition-base;
    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}

.preview-container {
  .card {
    transition: $transition-base;
  }
  .preview-image,
  .preview-image-2,
  .preview-image-3 {
    width: 100%;
    transition: all .6s ease-in-out;
  }

  .preview-image-3 {
    width: 50%;
    object-fit: contain;
    position: absolute;
    left: 25%;
    top: 2.5rem;
  }


  &:hover {
    .card {
      box-shadow: $box-shadow-lg;
      transform: scale(1.05);
    }
    @include media-breakpoint-up(lg) {
      .preview-image {
        width: 600px;
      }

      .preview-image-2 {
        width: 850px;
      }

      .preview-image-3 {
        width: 100%;
        left: 0;
      }
    }
  }
}

.choose-your-side-bg {
  background-size: 140%;
  background-repeat: no-repeat;
  background-position-x: -15%;
  background-position-y: 140%;
  @include media-breakpoint-down(lg) {
    background-position-y: -140%;
  }
  @include media-breakpoint-down(md) {
    background-position-y: 100%;
  }

}

.credits-row {
  @include margin(5rem 0 10rem 0);

  @include media-breakpoint-down(xl) {
    @include margin(14rem 0 10rem 0);
  }

  @include media-breakpoint-down(md) {
    @include margin(7rem 0 10rem 0);
  }

  @include media-breakpoint-down(sm) {
    @include margin(0 0 10rem 0);
  }
}

.hi-chat {
  position: absolute;
  animation: hi-chat 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: right;
}
@keyframes hi-chat {
  0% { top: calc(50% - 32px); right: 5%; opacity: 1; transform: scale(.3); }
  50% { top: 0; right: -30%; opacity: 0; transform: scale(1); }
  100% { top: 0; right: -30%; opacity: 0; transform: scale(1); }
}

.hi-quickmenu {
  position: absolute;
  animation: hi-quickmenu 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: right;
}
@keyframes hi-quickmenu {
  0% { bottom: 0; right: 20%; opacity: 0; transform: scale(1); }
  50% { bottom: calc(50% - 32px); right: 0; opacity: 1; transform: scale(.3); }
  100% { bottom: calc(50% - 32px); right: 0; opacity: 1; transform: scale(.3); }
}

.hi-search {
  position: absolute;
  animation: hi-search 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-search {
  0% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
  50% { bottom: 0; left: -30%; opacity: 0; transform: scale(1); }
  100% { bottom: 0; left: -30%; opacity: 0; transform: scale(1); }
}

.hi-menu {
  position: absolute;
  animation: hi-menu 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-menu {
  0% { top: 0; left: 0%; opacity: 0; transform: scale(.7); }
  50% { top: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
  100% { top: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
}

.hi-user {
  position: absolute;
  animation: hi-user 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-user {
  0% { bottom: 0; left: 30%; opacity: 0; transform: scale(.8); }
  50% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
  100% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
}

//
.hi-subteam {
  position: absolute;
  animation: hi-subteam 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: right;
}
@keyframes hi-subteam {
  0% { bottom: calc(50% - 32px); right: 5%; opacity: 1; transform: scale(.3); }
  50% { bottom: calc(50% - 32px); right: 5%; opacity: 1; transform: scale(.3); }
  100% { bottom: 0; right: -20%; opacity: 0; transform: scale(1); }
}

.hi-buttons {
  position: absolute;
  animation: hi-buttons 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: right;
}
@keyframes hi-buttons {
  0% { bottom: 0; right: 20%; opacity: 0; transform: scale(1); }
  50% { bottom: 0; right: 20%; opacity: 0; transform: scale(1); }
  100% { bottom: calc(50% - 32px); right: 0; opacity: 1; transform: scale(.3); }
}

.hi-tab {
  position: absolute;
  animation: hi-tab 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-tab {
  0% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
  50% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
  100% { bottom: 0; left: -30%; opacity: 0; transform: scale(1); }
}

.hi-backuser {
  position: absolute;
  animation: hi-backuser 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-backuser {
  0% { top: 0; left: 0%; opacity: 0; transform: scale(.7); }
  50% { top: 0; left: 0%; opacity: 0; transform: scale(.7); }
  100% { top: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
}

.hi-breadcrumb {
  position: absolute;
  animation: hi-breadcrumb 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: left;
}
@keyframes hi-breadcrumb {
  0% { bottom: 0; left: 30%; opacity: 0; transform: scale(.8); }
  50% { bottom: 0; left: 30%; opacity: 0; transform: scale(.8); }
  100% { bottom: calc(50% - 32px); left: 0; opacity: 1; transform: scale(.3); }
}

.active-text-header {
  animation: active-text-header 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform: $transition-base;
}
@keyframes active-text-header {
  0% {
    background: linear-gradient(90deg, $primary, $secondary);
    -webkit-text-fill-color: transparent;
    background-clip: text;

  }
  50% {
    background: linear-gradient(90deg, $primary, $secondary);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  51% {
    background: linear-gradient(90deg, $body-color, $body-color);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  100% {
    background: linear-gradient(90deg, $body-color, $body-color);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.active-text-subheader {
  animation: active-text-subheader 10s infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform: $transition-base;
}
@keyframes active-text-subheader {
  0% {
    background: linear-gradient(90deg, $body-color, $body-color);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  50% {
    background: linear-gradient(90deg, $body-color, $body-color);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  51% {
    background: linear-gradient(90deg, $primary, $secondary);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  100% {
    background: linear-gradient(90deg, $primary, $secondary);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.navbar-light .navbar-toggler {
  border: none;
}
